import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { Gap, Section, SEO, Post, Title, Text, Col } from '../components'
import { extractNodesFromData } from '../lib/helpers'

const openedArticles = 5

class News extends React.Component {
  render() {
    const { pathname, data } = this.props

    const { posts, contentImages } = extractNodesFromData(data)

    const openPosts = posts.slice(0, openedArticles)
    const restPosts = posts.slice(openedArticles, posts.length)

    return (
      <>
        <SEO
          title="News & Events | Zuri"
          description="Stay updated with Zuri’s latest developments on Hybrid VTOL aircraft, product innovations, and industry insights."
          pathname={pathname}
        />
        <Gap.Top />

        <Section background="black">
          <Col maxWidth="814px" alignItems="center" width="100%" margin="auto">
            <Gap gap="140px" mobileGap="40px" />

            <Title.Big
              innerAs="h1"
              fontWeight="400"
              white
              black={false}
              uppercase
              textAlign="center"
            >
              Zuri News & Events
            </Title.Big>
          </Col>
        </Section>

        <Gap gap="80px" mobileGap="40px" />

        <Section background="black">
          {openPosts.map((post, key) => (
            <Fragment key={post.slug}>
              <Post post={post} contentImages={contentImages} />
            </Fragment>
          ))}
          <Post.MorePosts posts={restPosts} />
        </Section>
      </>
    )
  }
}

export default News

export const pageQuery = graphql`
  query {
    contentImages: allFile(
      filter: { relativePath: { regex: "/^uploaded-image-/" } }
    ) {
      edges {
        node {
          ...PostContentImage
          relativePath
        }
      }
    }

    posts: allPosts(
      sort: { fields: createdAt, order: DESC }
      filter: { state: { eq: "published" } }
    ) {
      edges {
        node {
          ...PostFragment
        }
      }
    }
  }
`
{
  /* <Gap gap="24px" />

            <Text textAlign="center">
              Stay updated with the latest developments at Zuri, including
              product innovations, press releases, and news from the world of
              Hybrid VTOL aviation.
            </Text> */
}
